import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { ConfigService } from '../config.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';


import * as $ from "jquery";

@Component({
  selector: 'app-dialog-article',
  templateUrl: './dialog-article.component.html',
  styleUrls: ['./dialog-article.component.css']
})
export class DialogArticleComponent implements OnInit {

  constructor(private configService: ConfigService, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogArticleComponent>) { }


  mainFile : File;
  multipleFiles: Array<File>;
  form: FormGroup;

  error: string;
  uploadResponse = { status: '', message: '', filePath: '' };


  back() {
    this.dialogRef.close()
  }



  onSubmit(form) {

    form.dialogId = this.data.id;

    async function addArticle(that: any, _form : any) {

      let serverResponse = await that.configService.addArticle(_form, that.mainFile, that.multipleFiles);
      return serverResponse;

    }


    async function displayServerResponseDiv(that :any, _form : any){
      let serverResponse = await addArticle(that, _form);
      $("#loaderDiv").addClass("displayNone").removeClass("displayFlex");
      if(serverResponse == "1"){
        $("#successDiv").addClass("displayFlex").removeClass("displayNone");
        $("#errorDiv").addClass("displayNone").removeClass("displayFlex");
        setTimeout(function(){
            window.location.reload();
          }, 2000);
      } else if(serverResponse == "2"){
        $("#successDiv").addClass("displayNone").removeClass("displayFlex");
        $("#errorDiv").addClass("displayFlex").removeClass("displayNone");
        setTimeout(function(){
          window.location.reload();
        }, 2000);
      }

    }

    $("#loaderDiv").addClass("displayFlex").removeClass("displayNone");

    displayServerResponseDiv(this, form);

  }

  onFileChange(event) {

    let selectedFile = <File>event.target.files[0];

    var file_ext = selectedFile.name.substr(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length);

      this.mainFile = selectedFile;


  }


  onMultipleFilesChange(event){

    let multipleFiles = <Array<File>>event.target.files;

    if (multipleFiles.length < 13){



          for(let i = 0; i < multipleFiles.length ; i++){

          var file_ext = multipleFiles[i].name.substr(multipleFiles[i].name.lastIndexOf('.') + 1, multipleFiles[i].name.length);


        }

        this.multipleFiles = multipleFiles;


    }
    else {

    }


  }


  ngOnInit() {



  }

}
