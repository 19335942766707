import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import * as $ from 'jquery';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  pulse: any;

  // smooth-scroll
  smoothview(id){
    const element = document.getElementById(id);
    element.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }

  constructor(private router: Router) {
   }

  ngOnInit() {

    //scroll when loading and navigating
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

  }
}
