import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from '../config.service';


import * as $ from 'jquery';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private configService: ConfigService) {}


  async onSubmit(contactForm) {

    contactForm = JSON.stringify(contactForm);
    let result = await this.configService.sendContactForm(contactForm);

    if(result){
      $("#successAlert").fadeIn();
      setTimeout(function () { $("#successAlert").fadeOut(); }, 3000);
    }
    else{
      $("#dangerAlert").fadeIn();
      setTimeout(function () { $("#dangerAlert").fadeOut(); }, 3000);
    }
  }


  ngOnInit() {

    //scroll en haut quand on arrive
    window.onbeforeunload = function () {
      $("#body").fadeOut();
      window.scrollTo(0, 0);
    }

     //scroll when loading and navigating
     this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

  }
}
