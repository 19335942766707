import { Component, OnInit, Inject } from '@angular/core';
import { ConfigService } from '../config.service';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


import * as $ from "jquery";
@Component({
  selector: 'app-dialog-upload-image',
  templateUrl: './dialog-upload-image.component.html',
  styleUrls: ['./dialog-upload-image.component.css']
})
export class DialogUploadImageComponent implements OnInit {

  constructor(private configService: ConfigService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogUploadImageComponent>) { }

  selectedFile : File = null;

  isDisabled = true ;

  onFileSelected(event){

    this.selectedFile = <File>event.target.files[0];

    var file_ext = this.selectedFile.name.substr(this.selectedFile.name.lastIndexOf('.') + 1, this.selectedFile.name.length);

    this.isDisabled =  false;

  }


  onUpload(){


    async function uploadSingleImage(that :any){

      let serverResponse = await that.configService.changeSingleImage(that.selectedFile, that.data.id);
      return serverResponse;

    }

    async function displayServerResponseDiv(that :any){
      let serverResponse = await uploadSingleImage(that);
      $("#loaderDiv").addClass("displayNone").removeClass("displayFlex");
      if(serverResponse == "1"){
        $("#successDiv").addClass("displayFlex").removeClass("displayNone");
        $("#errorDiv").addClass("displayNone").removeClass("displayFlex");
        setTimeout(function(){
            window.location.reload();
          }, 2000);
      } else if(serverResponse == "2"){
        $("#successDiv").addClass("displayNone").removeClass("displayFlex");
        $("#errorDiv").addClass("displayFlex").removeClass("displayNone");
        setTimeout(function(){
          window.location.reload();
        }, 2000);
      }

    }

    $("#loaderDiv").addClass("displayFlex").removeClass("displayNone");

    displayServerResponseDiv(this);

  }





  back() {
    this.dialogRef.close()
  }

  ngOnInit() {
  }

}
