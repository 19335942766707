import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from '../config.service';
import {
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ImageServerDiv3Component } from '../image-server-div3/image-server-div3.component';
import { DialogUploadImageComponent } from '../dialog-upload-image/dialog-upload-image.component';

import * as $ from 'jquery';


@Component({
  selector: 'app-galerie',
  templateUrl: './galerie.component.html',
  styleUrls: ['./galerie.component.css']
})
export class GalerieComponent implements OnInit {

  constructor(private router: Router, private configService: ConfigService, private resolver: ComponentFactoryResolver, private dialog: MatDialog) { }

  @ViewChild('imageFromServerContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

  @ViewChild('imageFromServerContainer2', { static: true, read: ViewContainerRef }) entry2: ViewContainerRef;

  connectionStatus: boolean;

  navigateGalerieMariages() {
    this.router.navigate(['/galerie-mariage']);
  }

  navigateGalerieEvents() {
    this.router.navigate(['/galerie-evenements']);
  }

  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  openDialog(idDialogString: string) {

    let dialogRef = this.dialog.open(DialogUploadImageComponent, {
      height: 'auto',
      width: 'auto',
      data: { id: idDialogString }
    });
  }




  ngOnInit() {

    // get photos from server
    async function getSingleImage(that: any, id: any, entryContainer: any) {

      let serverResponse = await that.configService.getSingleImage(id);



      let generatedImage = that.configService.convertBase64ToBlobLocal(serverResponse);


      const factory = that.resolver.resolveComponentFactory(ImageServerDiv3Component);
      const componentRef = entryContainer.createComponent(factory);

      componentRef.instance.imageURL = generatedImage;


    }

    getSingleImage(this, 5, this.entry);
    getSingleImage(this, 6, this.entry2);


    this.connectionStatus = this.getConnectionStatus();


    //scroll en haut quand on arrive
    window.onbeforeunload = function () {
      $("#body").fadeOut();
      window.scrollTo(0, 0);
    }

     //scroll when loading and navigating
     this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

  }
}
