import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import * as $ from 'jquery';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  mySubscription: any;

  constructor(private router: Router) {

  }


  redirectToFacebook(){
    window.location.href = 'https://www.facebook.com/Flora-Maria-Organisatrice-d%C3%A9v%C3%A9nements-111402710396310/';
  }

  redirectToInstagram(){
    window.location.href = 'https://www.instagram.com/Floramaria.fr/';
  }

  redirectToPinterest(){
    window.location.href = 'https://www.pinterest.fr/floramariage/';
  }


  ngOnInit() {


    // jquerry animation init

    $(document).ready(function () {

      // Function which adds the 'animated' class to any '.animatable' in view
      var doAnimations = function () {

        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
          $animatables = $('.animatable');

        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }

        // Check all animatables and animate them if necessary
        $animatables.each(function (i) {
          var $animatable = $(this);
          if (($animatable.offset().top + $animatable.height() - 20) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
          }
        });

      };

      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');

    });


  }

}
