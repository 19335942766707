import {
  Component, OnInit, ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { Router } from '@angular/router';
import { GalerieDivComponent } from '../galerie-div/galerie-div.component';
import { trigger, style, animate, transition, state, useAnimation } from '@angular/animations';
import { pulse } from 'ng-animate';
import { DialogArticleComponent } from '../dialog-article/dialog-article.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-galerie-mariage',
  templateUrl: './galerie-mariage.component.html',
  styleUrls: ['./galerie-mariage.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeIn', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ]),
    [
      trigger('pulse', [transition('* => *', useAnimation(pulse, { params: { timings: 200, scale: 1.038 } }))]),
    ]
  ]
})

export class GalerieMariageComponent implements OnInit {


  constructor(private resolver: ComponentFactoryResolver, private router: Router, private configService: ConfigService, private dialog: MatDialog, private http: HttpClient) { }

  arrayLenghtRetrievedFromServer : any;
  connectionStatus: any;

  @ViewChild('galerieDivContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  openDialog(idDialogString: string) {
    let dialogRef = this.dialog.open(DialogArticleComponent, {
      maxHeight: '806px',
      width: 'auto',
      data: { id: idDialogString },
      id :"articleDialog"
    });
  }


  ngOnInit() {


    this.connectionStatus = this.getConnectionStatus();


    async function CreateGalerieDivComponent(that) {

        var arrayRetrieved = await that.configService.fetchJsonGalerieMariageArray();
        var newArray = [];

        arrayRetrieved.forEach(element => {
           element.mainImageSRC = that.configService.convertBase64ToBlobLocal(element.mainImageSRC);
           let localImagesArray = [];
           element.imagesArray.forEach(photo => {
             localImagesArray.push(that.configService.convertBase64ToBlobLocal(photo));
           });
          element.imagesArray = localImagesArray;
           newArray.push(element);
        });

        arrayRetrieved = newArray;

        let MariageArray = arrayRetrieved;
        let MariageArraylength = 0;

        for (let items in MariageArray) {
          MariageArraylength++;
        }

        that.arrayLenghtRetrievedFromServer = MariageArraylength;

        for (let i = 0; i < MariageArraylength; i++) {


          const factory = that.resolver.resolveComponentFactory(GalerieDivComponent);
          const componentRef = that.entry.createComponent(factory);

          componentRef.instance.names = MariageArray[i].names;
          componentRef.instance.date = MariageArray[i].date;
          componentRef.instance.mainImageSRC = MariageArray[i].mainImageSRC;
          componentRef.instance.id = MariageArray[i].id;
        }

    }

    CreateGalerieDivComponent(this);



  }
}
