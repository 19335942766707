import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-partenaire-div',
  templateUrl: './partenaire-div.component.html',
  styleUrls: ['./partenaire-div.component.css']
})
export class PartenaireDivComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer, private configService: ConfigService) { }

  @Input() id: string;
  @Input() imageURL: string;

  connectionStatus: boolean;

  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
  }

  deletePartenaire() {

    if (confirm("Supprimer Le Partenaire?")) {

      async function deletePartenaire(that: any) {


        let serverResponse = await that.configService.deletePartenaire(that.id);
        return serverResponse;
      }

      async function reloadPageAfterServerResponse(that :any,){
        let serverResponse = await deletePartenaire(that);
        if(serverResponse == "1"){

          if(confirm('Supression terminée, cliquez sur OK pour recharger la page.')){
            window.location.reload();
        }

        } else if(serverResponse == "2"){

          if(confirm('Erreur lors de la supression, cliquez sur OK pour recharger la page.')){
            window.location.reload();
        }

        }

      }


      reloadPageAfterServerResponse(this);

    }
    else {

    return;
    }
  }

  ngOnInit() {

    this.connectionStatus = this.getConnectionStatus();
  }

}
