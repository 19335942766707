import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


import * as $ from "jquery";
@Component({
  selector: 'app-dialog-badge',
  templateUrl: './dialog-badge.component.html',
  styleUrls: ['./dialog-badge.component.css']
})
export class DialogBadgeComponent implements OnInit {

  constructor(private configService: ConfigService, public dialogRef: MatDialogRef<DialogBadgeComponent>) { }

  selectedFile : File = null;

  isDisabled = true ;

  onFileSelected(event){

    this.selectedFile = <File>event.target.files[0];

    this.isDisabled = false;
  }


  onUpload(){

    async function addBadge(that :any){

      let serverResponse = await that.configService.addBadge(that.selectedFile);
      return serverResponse;
    }

    async function displayServerResponseDiv(that :any){
      let serverResponse = await addBadge(that);
      $("#loaderDiv").addClass("displayNone").removeClass("displayFlex");
      if(serverResponse == "1"){
        $("#successDiv").addClass("displayFlex").removeClass("displayNone");
        $("#errorDiv").addClass("displayNone").removeClass("displayFlex");
        setTimeout(function(){
            window.location.reload();
          }, 2000);
      } else if(serverResponse == "2"){
        $("#successDiv").addClass("displayNone").removeClass("displayFlex");
        $("#errorDiv").addClass("displayFlex").removeClass("displayNone");
        setTimeout(function(){
          window.location.reload();
        }, 2000);
      }

    }

    $("#loaderDiv").addClass("displayFlex").removeClass("displayNone");

    displayServerResponseDiv(this);
  }

  back() {
    this.dialogRef.close()
  }



  ngOnInit() {
  }

}
